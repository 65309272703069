<template>
  <div class="Retrievalpersonal">
    <Headernav></Headernav>
    <Retrievalnav></Retrievalnav>
    <!-- 主体部分 -->
    <div class="banner">
      <PersonalSide @func="gettype"></PersonalSide>
      <div v-show="datatype == 1" class="rightpersonal">
        <p class="title">
          <em></em> 您当前的位置： 首页 > 我的图书馆 > 个人中心 > 借阅历史
        </p>
        <div
          style="color: #ccc; text-align: center; font-size: 14px; margin-top: 200px"
          v-if="(datatype == 1 && token == '') || token == undefined"
        >
          先登录才能查看您的借阅历史哦
        </div>
        <div v-if="datatype == 1 && token">
          <div class="checktab">
            <label>操作类型：</label>
            <span class="active">全部</span>
            <span>借书</span>
          </div>
          <div class="timedata">
            <label>开始时间：</label>
            <el-date-picker v-model="value1" type="date" placeholder="选择日期">
            </el-date-picker>
            -
            <label>结束时间：</label>
            <el-date-picker v-model="value1" type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="calendar">
            <span class="active">2021</span>
            <span>2020</span>
            <span>2019</span>
            <span>2018</span>
          </div>
          <div class="dateline">
            <div class="left">
              <p class="datetop">
                <span class="active">2021</span
                ><img src="../images/ret2.png" alt="" /><span
                  class="line activeline"
                ></span>
              </p>
              <p class="date_time">
                <span>2021-12-18</span><em></em><span class="line activeline"></span>
              </p>
              <!-- <p class="date_time">
              <span>2021-12-18</span><em></em><span class="line activeline"></span>
            </p>
            <p class="date_time">
              <span>2021-12-18</span><em></em><span class="line activeline"></span>
            </p>
            <p class="date_time">
              <span>2021-12-18</span><em></em><span class="line activeline"></span>
            </p>
            <p class="date_time">
              <span>2021-12-18</span><em></em><span class="line activeline"></span>
            </p>
            <p class="date_time">
              <span>2021-12-18</span><em></em><span class="line activeline"></span>
            </p> -->
            </div>
            <div class="right">
              <div class="list" v-for="(i, k) in datelists" :key="k">
                <p v-if="i.logType == 30001">你借出了《{{ i.biblios.title }}}》</p>
                <p v-if="i.logType == 30002">你还了《{{ i.biblios.title }}}》</p>
              </div>
              <!-- <div class="list">
              <p>你借出了《西游记》</p>
              <p>你借出了《西游记》</p>
            </div>
            <div class="list">
              <p>你借出了《西游记》</p>
              <p>你借出了《西游记》</p>
            </div>
            <div class="list">
              <p>你借出了《西游记》</p>
              <p>你借出了《西游记》</p>
            </div>
            <div class="list">
              <p>你借出了《西游记》</p>
              <p>你借出了《西游记》</p>
            </div>
            <div class="list">
              <p>你借出了《西游记》</p>
              <p>你借出了《西游记》</p>
            </div> -->
            </div>
          </div>

          <div class="dateline">
            <div class="left">
              <p class="datetop">
                <span>2021</span><img src="../images/ret3.png" alt="" /><span
                  class="line"
                ></span>
              </p>
              <p class="date_time">
                <span>2021-12-18</span><em></em><span class="line"></span>
              </p>
              <p class="date_time">
                <span>2021-12-18</span><em></em><span class="line"></span>
              </p>
            </div>
            <div class="right">
              <div class="list">
                <p>你借出了《西游记》</p>
                <p>你借出了《西游记》</p>
              </div>
              <div class="list">
                <p>你借出了《西游记》</p>
                <p>你借出了《西游记》</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="datatype == 2" class="rightpersonal myBorrow">
        <p class="title">
          <em></em> 您当前的位置： 首页 > 我的图书馆 > 个人中心 > 我的借阅
        </p>
        <div
          style="color: #ccc; text-align: center; font-size: 14px; margin-top: 200px"
          v-if="(datatype == 2 && token == '') || token == undefined"
        >
          先登录才能查看您的借阅历史哦
        </div>
        <!-- <div v-if="datatype == 2 && token">
          <span class="delete">删除</span>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ textAlign: 'center' }"
            :cell-style="{ textAlign: 'center' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="日期" width="120">
              <template slot-scope="scope">{{ scope.row.date }}</template>
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="120"> </el-table-column>
            <el-table-column prop="address" label="地址" show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </div> -->
      </div>
      <div v-show="datatype == 3" class="rightpersonal myBorrow">
        <p class="title">
          <em></em> 您当前的位置： 首页 > 我的图书馆 > 个人中心 > 我的预约
        </p>
        <!-- <div
          style="color: #ccc; text-align: center; font-size: 14px; margin-top: 200px"
          v-if="(datatype == 2 && token == '') || token == undefined"
        >
          先登录才能查看您的借阅历史哦
        </div> -->
        <div>
          <span class="delete">取消预约</span>
          <el-table
            ref="multipleTable"
            :data="reslists"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ textAlign: 'center' }"
            :cell-style="{ textAlign: 'center' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="书目记录号" width="120">
              <template slot-scope="scope">{{ scope.row.bookrecno }}</template>
            </el-table-column>
            <el-table-column label="读者证号" width="120">
              <template slot-scope="scope">{{ scope.row.rdid }}</template>
            </el-table-column>
            <el-table-column label="条码号" width="120">
              <template slot-scope="scope">{{ scope.row.barcode }}</template>
            </el-table-column>
            <el-table-column label="取书地点" width="120">
              <template slot-scope="scope">{{ scope.row.pickuplocal }}</template>
            </el-table-column>
            <el-table-column label="预约时间" width="120">
              <template slot-scope="scope">{{ scope.row.canceldate }}</template>
            </el-table-column>
            <el-table-column label="预约取书馆" width="120">
              <template slot-scope="scope">{{ scope.row.reslib }}</template>
            </el-table-column>
            <el-table-column label="还书地点" width="120">
              <template slot-scope="scope">{{ scope.row.returnlocal }}</template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-show="datatype == 5" class="rightpersonal myBorrow">
        <p class="title">
          <em></em> 您当前的位置： 首页 > 我的图书馆 > 个人中心 > 我的收藏
        </p>
        <div
          style="color: #ccc; text-align: center; font-size: 14px; margin-top: 200px"
          v-if="(datatype == 5 && token == '') || token == undefined"
        >
          先登录才能查看您的收藏
        </div>
        <div v-if="datatype == 5 && token">
          <span class="delete" @click="delect()">删除</span>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ textAlign: 'center' }"
            :cell-style="{ textAlign: 'center' }"
            @selection-change="handleSelectionChangetabel"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="收藏日期">
              <template slot-scope="scope">{{ scope.row.createTime }}</template>
            </el-table-column>
            <el-table-column prop="title" label="书名">
              <template slot-scope="scope">{{ scope.row.title }}</template>
            </el-table-column>
            <el-table-column prop="author" label="作者">
              <template slot-scope="scope">{{ scope.row.author }}</template>
            </el-table-column>
            <el-table-column prop="publisher" label="出版社">
              <template slot-scope="scope">{{ scope.row.publisher }}</template>
            </el-table-column>
            <el-table-column prop="bookType" label="图书类型">
              <template slot-scope="scope">{{ scope.row.bookType }}</template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Headernav from "../components/headernav.vue";
import Footer from "../components/footer.vue";
import Retrievalnav from "../components/retrievalnav.vue";
import PersonalSide from "../components/personalSide.vue";
import apiborrow from "../api/api_borrow";

export default {
  name: "Retrievalpersonal",
  data() {
    return {
      value1: "",
      datatype: 1,
      tableData: [],
      datelists: [],
      checktabelids: "",
      token: "",
      reslists: [],
    };
  },
  components: {
    Headernav,
    Footer,
    Retrievalnav,
    PersonalSide,
  },
  watch: {},
  methods: {
    gettype(data) {
      this.datatype = data;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delect() {
      if (this.checktabelids == "") {
        this.$message({
          message: "请先选择所删除的数据",
          type: "warning",
        });
      } else {
        apiborrow.getcollectremove({ ids: this.checktabelids }).then((res) => {
          if (res.code == 0) {
            this.getcollect();
            this.$message({
              message: "成功",
              type: "success",
            });
          }
        });
      }
    },
    handleSelectionChangetabel(val) {
      const checktabelid = [];
      if (val) {
        val.forEach((row) => {
          checktabelid.push(row.id);
        });
      }
      this.checktabelids = checktabelid.join(",");
    },
    getborrowlists() {
      apiborrow.getHistoryLoanList({ pageSize: "10", toPage: "1" }).then((res) => {
        if (res.code == 0) {
          this.datelists = res.data;
        }
      });
    },
    getcollect() {
      apiborrow.getcollectlist().then((res) => {
        if (res.code == 0) {
          this.tableData = res.rows;
        }
      });
    },
    getsearchreslist() {
      apiborrow.getsearchreslist().then((res) => {
        if (res.code == 0) {
          this.reslists = res.data;
        }
      });
    },
  },
  created() {
    this.getborrowlists();
    this.getcollect();
    this.getsearchreslist();
    this.token = localStorage.getItem("tokenlogin");
       if (this.token == null) {
      this.$message("请先登录");
      setTimeout(() => {
        this.$router.push({ path: "/accountlogin" });
      }, 1000);
    }
  }
};
</script>
<style scope>
.Retrievalpersonal {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  
}
 .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none !important;
}
 .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
  border-bottom: none !important;
}
.Retrievalpersonal .banner {
  width: 1200px;
  min-height: 700px;
  margin: 0 auto;
  display: flex;
}
.Retrievalpersonal .banner .rightpersonal {
  width: 100%;
  height: auto;
  min-height: 400px;
  padding: 10px 15px;
}
.Retrievalpersonal .banner .rightpersonal .title em {
  display: inline-block;
  width: 3px;
  height: 21px;
  background-color: #ff7701;
  vertical-align: middle;
  margin-right: 10px;
}
.Retrievalpersonal .banner .rightpersonal .title {
  font-size: 14px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.Retrievalpersonal .banner .rightpersonal .checktab {
  font-size: 14px;
}
.Retrievalpersonal .banner .rightpersonal .checktab span {
  display: inline-block;
  margin: 0px 10px;
  padding: 5px 20px;
}
.Retrievalpersonal .banner .rightpersonal .checktab .active {
  background-color: #ff7701;
  border-radius: 3px;
  color: #ffffff;
  padding: 5px 20px;
}
.Retrievalpersonal .banner .rightpersonal .timedata {
  font-size: 14px;
  margin: 20px 0px;
}
.Retrievalpersonal .banner .rightpersonal .calendar {
  width: 850px;
  height: 40px;
  line-height: 40px;
  background-color: #eeeeee;
  border-radius: 10px;
  display: flex;
  padding: 0px 20px;
  margin-bottom: 30px;
}
.Retrievalpersonal .banner .rightpersonal .calendar span {
  display: inline-block;
  width: 80px;
  text-align: center;
}
.Retrievalpersonal .banner .rightpersonal .calendar .active {
  background-image: url(../images/ret1.png);
  width: 80px;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  height: 52px;
}
.Retrievalpersonal .banner .rightpersonal .dateline {
  display: flex;
}
.Retrievalpersonal .banner .rightpersonal .dateline .left {
  width: 100px;
  height: auto;
  padding: 0px 5px;
}
.Retrievalpersonal .banner .rightpersonal .dateline .left .datetop {
  font-size: 18px;
  color: #333;
  letter-spacing: 3px;
  font-weight: 700;
  position: relative;
  height: 49px;
  margin-top: 4px;
}
.Retrievalpersonal .banner .rightpersonal .dateline .left .datetop .active {
  color: #ff7701;
}
.Retrievalpersonal .banner .rightpersonal .dateline .left .datetop .line {
  top: 25px;
}

.Retrievalpersonal .banner .rightpersonal .dateline .left .datetop img {
  vertical-align: middle;
  width: 20px;
  margin-left: 20px;
}
.Retrievalpersonal .banner .rightpersonal .dateline .left em {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #bbb4b4;
  margin-left: 6px;
  vertical-align: middle;
}
.Retrievalpersonal .banner .rightpersonal .dateline .left .date_time {
  position: relative;
  font-size: 12px;
  color: #333;
  padding-left: 10px;
  height: 70px;
}
.Retrievalpersonal .banner .rightpersonal .dateline .left .line {
  position: absolute;
  width: 1.3px;
  height: 75px;
  background-color: #eeeeee;
  top: 13px;
  right: 13px;
}
.Retrievalpersonal .banner .rightpersonal .dateline .left .activeline {
  background-color: #ac7345;
}
.Retrievalpersonal .banner .rightpersonal .dateline .right {
  width: 1100px;
  height: auto;
  margin: 42px 0px 0px;
}
.Retrievalpersonal .banner .rightpersonal .dateline .right .list {
  width: 760px;
  height: auto;
  background-color: #eeeeee;
  padding-bottom: 5px;
  padding-left: 25px;
  border-radius: 10px;
  margin-top: 17px;
  font-size: 14px;
}
.Retrievalpersonal .banner .rightpersonal .dateline .right .list p {
  margin: 0px;
  padding: 0px;
  padding-top: 8px;
}
.Retrievalpersonal .banner .myBorrow .delete {
  display: inline-block;
  width: 100px;
  height: 35px;
  line-height: 35px;
  background-color: #ebebeb;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  float: right;
  margin-bottom: 10px;
}
.el-table th {
  padding: 0;
  height: 35px;
  background-color: #ff9234;
  color: white;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
}
.el-table th,
.el-table tr {
  height: 45px;
}
</style>
