<template>
  <div class="sidehead">
    <div class="sidebody">
      <div class="sideblack"></div>
      <div class="sidebrown">
        <p>个人中心</p>
      </div>
      <div class="sidebottom">
        <p>PERSONAL CENTER</p>
      </div>
      <div :class="active == 1 ? 'sideall' : 'classification'" @click="sendtype(1)">
        <p style="margin-left: 25px">借阅历史</p>
        <img v-show="active == 1" src="../images/sidemore.png" alt="" />
      </div>
      <div
        :class="active == 2 ? 'sideall' : 'classification'"
        class="classification"
        @click="sendtype(2)"
      >
        <p style="margin-left: 25px">我的借阅</p>
        <img v-show="active == 2" src="../images/sidemore.png" alt="" />
      </div>
      <div :class="active == 3 ? 'sideall' : 'classification'" @click="sendtype(3)">
        <p style="margin-left: 25px">我的预约</p>
      </div>
      <div :class="active == 4 ? 'sideall' : 'classification'" @click="sendtype(4)">
        <p style="margin-left: 25px">到馆通知</p>
      </div>
      <div :class="active == 5 ? 'sideall' : 'classification'" @click="sendtype(5)">
        <p style="margin-left: 25px">我的收藏</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClassificationSide",
  data() {
    return {
      active: 1,
    };
  },
  methods: {
    sendtype(type) {
      this.$emit("func", type);
      this.active = type;
    },
  },
};
</script>

<style scoped>
.sidehead {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sidebody {
  width: 260px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sideblack {
  width: 260px;
  height: 64px;
  background-color: #424242;
}
.sidebrown {
  width: 180px;
  height: 40px;
  background-color: #ff7701;
  margin: -40px auto;
  text-align: center;
}
.sidebrown p {
  
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 38px;
  letter-spacing: 1px;
  color: #f6f6f6;
  line-height: 6px;
}
.sidebottom {
  width: 180px;
  height: 29px;
  background-color: #ff9234;
  margin: 40px auto;
  text-align: center;
}
.sidebottom p {
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  line-height: 1px;
}
.sideall {
  width: 229px;
  display: flex;
  justify-content: space-between;
  height: auto;
  margin: 0 auto;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
}
.sideall img {
  width: 10px;
  height: 18px;
}
.sideall p {
  font-size: 14px;
  color: #ff7701;
  
  margin-left: 25px;
}
.classification {
  width: 229px;
  height: 50px;
  border-bottom: 1px solid #eeeeee;
  text-align: inherit;
  margin: 0 auto;
  font-size: 14px;
}
</style>
